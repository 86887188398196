<template>
  <page-header title="Statistics Dashboard">
    <button type="button" class="btn-primary ml-auto h-10" @click="download">
      <DocumentArrowDownIcon class="size-5 -ml-1 mr-2" />
      Export
    </button>
  </page-header>

  <div class="container mx-auto p-6">
    <div class="mb-6 bg-white p-4 rounded-lg shadow">
      <div class="flex gap-4">
        <div class="flex-1">
          <label class="block text-sm font-medium text-gray-700">Section</label>
          <select
            v-model="filters.section"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            @change="fetchData"
          >
            <option value="session">Sessions</option>
            <option value="user">Users</option>
          </select>
        </div>
        <div class="flex-1">
          <label class="block text-sm font-medium text-gray-700">Start Date</label>
          <input
            v-model="filters.from"
            type="datetime-local"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            @change="fetchData"
          >
        </div>
        <div class="flex-1">
          <label class="block text-sm font-medium text-gray-700">End Date</label>
          <input
            v-model="filters.to"
            type="datetime-local"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            @change="fetchData"
          >
        </div>
      </div>
    </div>

    <div class="relative">
      <transition
        enter-active-class="transition ease-out"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
        appear
      >
        <div v-if="isLoading" class="flex items-center justify-center mt-36 absolute inset-0">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500" />
        </div>

        <div v-else-if="filters.section === 'session'" class="absolute inset-0">
          <stats-sessions :sessions="statsData" :session-duration="sessionDuration" :format-time="formatTime" />
        </div>

        <div v-else-if="filters.section === 'user'" class="absolute inset-0">
          <stats-users :users="statsData" :format-time="formatTime" />
        </div>

        <div v-else class="hidden">
          How does it happens?
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import PageHeader from '../components/PageHeader.vue';
import StatsSessions from '../components/StatsSessions.vue';
import StatsUsers from '../components/StatsUsers.vue';
import { useToast } from 'vue-toastification';
import { DocumentArrowDownIcon } from '@heroicons/vue/24/outline';
import * as XLSX from 'xlsx';

dayjs.extend(duration);
dayjs.extend(utc);

const toast = useToast();

// current time
const to = dayjs().format('YYYY-MM-DDTHH:mm');
// start of the day
const from = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm');

const section = 'session';

const filters = reactive({ from, to, section });
const statsData = ref([]);
const isLoading = ref(false);

async function fetchData() {
  const minLoadingTime = 350; // ms, make animation smoother
  const loadingStart = Date.now();

  try {
    isLoading.value = true;
    const response = await fetch(
      `/api/stat/${filters.section}?from=${filters.from}&to=${filters.to}`
    );
    statsData.value = await response.json();

  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error('Error fetching data');

  } finally {
    const loadTime = Date.now() - loadingStart;
    setTimeout(() => {
      isLoading.value = false;
    }, Math.max(0, minLoadingTime - loadTime));

  }
}

function formatTime(seconds) {
  return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
}

function sessionDuration(session) {
  return dayjs(session.finishedAt).diff(dayjs(session.startedAt), 'second');
}

function download() {
  if (!statsData.value.length) {
    toast.info('No data to export');
    return;
  }

  const data = parseData();

  const ws = XLSX.utils.json_to_sheet(data);
  ws['!cols'] = fitToColumn(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `${filters.section}s`);

  const a = document.createElement('a');
  const url = URL.createObjectURL(new Blob([ XLSX.write(wb, { type: 'array', bookType: 'xlsx' }) ]));
  a.href = url;
  a.download = `${filters.section}s_${filters.from}_${filters.to}.xlsx`;
  a.click();
}

function parseData() {
  if (filters.section === 'session') {
    return statsData.value.map(session => ({
      'email': session.email,
      'session': 1, // NOTE: we know this is 1 because it's a single session
      'time': sessionDuration(session),
      'program': session.program,
      'isEmailConfirmed': true, // NOTE: we know this is true, because we are fetching sessions for logged in user
      'language': session.language || 'n/a',
      'loginMethod': session.loginMethod || 'n/a',
      'browser': session.browser || 'n/a',
      'resolution': session.resolution || 'n/a',
      'OS': session.os || 'n/a',
      'utm_campaign': session.utm_campaign || 'n/a',
      'utm_content': session.utm_content || 'n/a',
      'utm_medium': session.utm_medium || 'n/a',
      'utm_source': session.utm_source || 'n/a',
      'utm_term': session.utm_term || 'n/a',
      'country': session.countryCode || 'n/a',
      'date': session.startedAt
    }));
  }

  if (filters.section === 'user') {
    return statsData.value.map(user => ({
      'email': user.email,
      'isEmailConfirmed': user.isEmailConfirmed,
      'sessionCount': user.sessionCount,
      'timeLeftSeconds': user.timeLeftSeconds,
      'createdAt': user.createdAt,
      'language': user.language || 'n/a',
      'loginMethod': user.loginMethod || 'n/a',
      'utm_campaign': user.utm_campaign || 'n/a',
      'utm_content': user.utm_content || 'n/a',
      'utm_medium': user.utm_medium || 'n/a',
      'utm_source': user.utm_source || 'n/a',
      'utm_term': user.utm_term || 'n/a',
      'countryCode': user.countryCode || 'n/a'
    }));
  }

  return null;
}

function fitToColumn(data) {
  return data.reduce((acc, row) => {
    Object.keys(row).forEach((key, i) => {
      const len = Math.max(String(key).length, String(row[key]).length);
      if (!acc[i] || acc[i] < len) {
        acc[i] = len;
      }
    });
    return acc;
  }, []).map(len => ({ wch: len }));
}

fetchData();
</script>
