<template>
  <page-header title="User" :identifier="userId">
    <template v-if="isLoaded">
      <button class="btn-outline-primary ml-auto h-10 mr-2" @click="toggleEnableDisableUser">
        <template v-if="user.deletedAt">
          <EyeIcon class="size-5 -ml-1 mr-2" />
          Enable user
        </template>
        <template v-else>
          <EyeSlashIcon class="size-5 -ml-1 mr-2" />
          Disable user
        </template>
      </button>
      <button class="btn-outline-primary ml-auto h-10 mr-2" @click="addTime">
        <ClockIcon class="size-5 -ml-1 mr-2" />
        Add 10 hours
      </button>
      <button class="btn-primary ml-auto h-10" :class="{'disabled': isSaving || !isDataChanged}" @click="save">
        <CircleStackIcon class="size-5 -ml-1 mr-2" />
        Save
      </button>
    </template>
  </page-header>

  <pre v-if="false">
      {{ JSON.stringify(user, null, 2) }}
    </pre>

  <template v-if="isLoaded">
    <user-form ref="userFormRef" :user="user" />

    <template v-if="userId">
      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-2 mb-4 mt-6">User Information</h2>
      <div class="grid grid-cols-1 gap-2 text-sm">
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Available hours:</span>
          <user-can-run-programs :row="user" />
        </div>
      </div>

      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-2 mb-4 mt-6">Subscription History</h2>
      <div v-if="user.SubscriptionHistory.length" class="space-y-4">
        <div v-for="(item, index) in user.SubscriptionHistory" :key="index" class="p-4 border rounded-lg bg-gray-50">
          <div class="text-lg font-medium flex items-center">
            <DocumentTextIcon class="size-5 -ml-1 mr-2" /> Type: <span class="capitalize">{{ item.type }}</span>
          </div>
          <div class="text-gray-600 flex items-center">
            <CalendarIcon class="size-5 -ml-1 mr-2" /> Created date: {{ formatDate(item.createdAt) }}
          </div>
          <div class="mt-2 text-gray-700">
            <div class="font-medium flex items-center">
              <BookmarkIcon class="size-5 -ml-1 mr-2" /> Details:
            </div>
            <ul class="ml-4 list-disc text-sm">
              <li class="flex items-center">
                <TagIcon class="size-5 -ml-1 mr-2" /> Source: {{ item.details.source || 'Unknown' }}
              </li>
              <li class="flex items-center">
                <ClockIcon class="size-5 -ml-1 mr-2" /> Time added: {{ formatTime(item.details.secondsAdded) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p v-else class="text-gray-500">No active subscriptions</p>
    </template>
  </template>

  <div v-else>
    Loading...
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount, toRaw } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import PageHeader from '../components/PageHeader.vue';
import UserForm from '../components/UserForm.vue';
import UserCanRunPrograms from '../components/UserCanRunPrograms.vue';
import {
  CircleStackIcon,
  EyeIcon,
  EyeSlashIcon,
  ClockIcon,
  BookmarkIcon,
  CalendarIcon,
  DocumentTextIcon,
  TagIcon
} from '@heroicons/vue/24/outline';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';
import hashSum from 'hash-sum';

const props = defineProps({
  userId: {
    type: String,
    default: null
  }
});

const toast = useToast();
const userFormRef = ref(null);
const isLoaded = ref(false);
const isSaving = ref(false);
const user = ref({});
const userHashSum = ref(null);

const isDataChanged = computed(() => userHashSum.value !== hashSum(user.value));

if (props.userId) {
  load();
} else {
  isLoaded.value = true;
}

async function load() {
  const { data } = await useFetch(`${apiDomain}/api/user/${props.userId}`).get().json();
  userHashSum.value = hashSum(data.value);
  user.value = data.value;
  isLoaded.value = true;
}

async function save() {
  isSaving.value = true;

  userFormRef.value.onValidate();
  if (userFormRef.value.getResult().$invalid) {
    return;
  }

  const { data, error } = await useFetch(`${apiDomain}/api/user`).post(toRaw(user.value)).json();

  isSaving.value = false;

  if (error.value) {
    console.log(error.value, data.value);
    toast.error(error.value);
  }

  if (data.value) {
    userHashSum.value = hashSum(data.value);
    user.value = data.value;
    toast.success('User saved successfully');
  }
}

function formatDate(dateString) {
  if (!dateString) {
    return '-';
  }

  return new Date(dateString).toLocaleString('en-GB', { timeZone: 'UTC', dateStyle: 'medium', timeStyle: 'long' });
}

function formatTime(seconds) {
  return seconds ? `${seconds / 3600} hours` : '-';
}

async function toggleEnableDisableUser() {
  const direction = user.value.deletedAt ? 'enable' : 'disable';
  const { data } = await useFetch(`${apiDomain}/api/user/${direction}/${user.value.id}`).get().json();

  if (data.value?.success) {
    load();
    toast.success(`User ${direction}d successfully`);
    return;
  }

  toast.error('Error enable user');
}

async function addTime() {
  const { data } = await useFetch(`${apiDomain}/api/user/add-time/${user.value.id}`).get().json();

  if (data.value?.success) {
    load();
    toast.success('User recieved +10 hours');
    return;
  }

  toast.error('Error adding +10 hours');
}

function handleBeforeUnload(e) {
  if (!isDataChanged.value || !Object.keys(user.value).length) {
    return;
  }
  e.preventDefault();
  e.returnValue = '';
}

onBeforeRouteLeave(async (to, from, next) => {
  if (!isDataChanged.value || !Object.keys(user.value).length) {
    return next();
  }

  const confirm = await Modal.confirm({
    title: 'Unsaved changes',
    body: `You have unsaved changes. <br/> If you leave this page, your changes will be lost. <br/> Are you sure you want to exit without saving?`,
    okButton: 'Leave',
    iconColor: 'primary'
  });

  return confirm ? next() : next(false);
});

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
