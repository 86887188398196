<template>
  <page-header title="Sesions">
    <button type="button" class="btn-primary ml-auto h-10" @click="terminateAll">
      <FireIcon class="size-5 -ml-1 mr-2" />
      Terminate All
    </button>
  </page-header>

  <programs-empty v-if="isEmpty && search === null" />

  <template v-else>
    <div class="flex justify-between mb-4">
      <input-search v-model.trim="search" class="max-w-lg grow" />

      <reload-button class="ml-4" @click="reloadGrid" />
    </div>

    <grid
      ref="gridRef"
      :data-handler="getSessions"
      :columns="columns"
      :row-class="rowClass"
      :per-page="15"
      @terminate="terminate"
    />
  </template>
</template>

<script setup>
import { ref, markRaw, computed } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import ReloadButton from '../components/ReloadButton.vue';
import InputSearch from '../components/InputSearch.vue';
import ProgramsEmpty from '../components/ProgramsEmpty.vue';
import SessionListActions from '../components/SessionListActions.vue';
import Grid from '../components/grid/Grid.vue';
import SessionUserIcon from '../components/SessionUserIcon.vue';
import SessionProgramIcon from '../components/SessionProgramIcon.vue';
import { useFetch, watchDebounced, useUrlSearchParams } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';
import { FireIcon } from '@heroicons/vue/24/outline';

const gridRef = ref(null);
const isEmpty = ref(false);
const router = useRouter();
const toast = useToast();

function rowClass(_row, index) {
  if (_row.tier === 'admin') {
    return 'bg-primary-100/50';
  }
  if (_row.tier === 'seo') {
    return 'bg-fuchsia-100/50';
  }
  return (index % 2 ? 'bg-zinc-300/10' : '') + (_row.deletedAt ? ' [&>td:not(:last-child)]:opacity-30' : ''); // stripes class for row
}

function reloadGrid(updateQuery = {}) {
  if (gridRef.value?.reload) {
    gridRef.value.reload(updateQuery);
  }
}

const params = useUrlSearchParams('history', { initialValue: { search: null } });

const search = computed({
  get: () => params.search,
  set: value => params.search = value
});

watchDebounced(
  search,
  () => {
    reloadGrid({ offset: 0 });
  },
  { debounce: 500, maxWait: 1000 }
);

function getUrl({ limit, offset, sort, direction }) {
  const data = { limit, offset, order: sort, direction: direction?.toLowerCase(), search: search.value ?? '' };

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/session/?${searchParams}`;
}

async function getSessions(args) {
  const { limit, offset, sort, direction } = args;
  const { data } = await useFetch(getUrl({ limit, offset, sort, direction })).get().json();

  isEmpty.value = false;

  return {
    total: data.value.total,
    rows: data.value.sessions
  };
}

const columns = [
  {
    title: '#',
    prop: 'id',
    class: 'cursor-pointer text-xs font-mono',
    sortable: true,
    click: row => router.push(`/session/${row.id}`)
  },
  {
    title: 'State',
    prop: 'state',
    sortable: true
  },
  {
    title: 'Duration',
    class: 'text-right',
    value: row => {
      if (!row.finishedAt) {
        return '-';
      }

      const duration = new Date(row.finishedAt) - new Date(row.startedAt || row.createdAt);
      const hours = Math.floor(duration / 1000 / 60 / 60);
      let minutes = Math.floor((duration / 1000 / 60) % 60);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      let seconds = Math.floor((duration / 1000) % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    }
  },
  {
    title: 'User',
    prop: 'userId',
    class: 'cursor-pointer !py-0',
    component: markRaw(SessionUserIcon),
    click: row => router.push(`/user/${row.userId}`),
    sortable: true
  },
  {
    title: 'Program',
    prop: 'programId',
    class: 'cursor-pointer !py-0',
    component: markRaw(SessionProgramIcon),
    click: row => router.push(`/program/${row.programId}`),
    sortable: true
  },
  {
    title: 'Date',
    prop: 'createdAt',
    value: row => new Date(row.createdAt).toLocaleString('en-GB', { timeZone: 'UTC', dateStyle: 'medium', timeStyle: 'long' }),
    sortable: true
  },
  {
    class: 'no-animation text-right max-w-max! z-10 relative',
    component: markRaw(SessionListActions)
  }
];

async function terminateAll() {
  const confirm = await Modal.confirm({
    title: 'Unrevertable changes',
    body: `You going to do unrevertable changes. <br/> Are you sure you want to terminate all sessions?`,
    okButton: 'Kill`em all',
    iconColor: 'primary'
  });

  if (!confirm) {
    return;
  }

  const { data } = await useFetch(`${apiDomain}/api/session/terminate-all`).get().json();

  if (!data.value?.success) {
    toast.error('Something went wrong');
    return;
  }

  reloadGrid();
  toast.success(`All ${data.value.terminated} sessions terminated successfully`);
}

async function terminate({ id }) {
  const confirm = await Modal.confirm({
    title: 'Terminate Session',
    body: `Terminate the session #${id}?`,
    okButton: 'Terminate',
    iconColor: 'primary'
  });

  if (!confirm) {
    return;
  }

  const { data } = await useFetch(`${apiDomain}/api/session/terminate/${id}`).get().json();

  if (!data.value?.success) {
    toast.error('Error terminating session');
    return;
  }

  reloadGrid();
  toast.success('Session terminated successfully');
}
</script>
