<template>
  <Popover class="absolute inset-0">
    <PopoverButton class="absolute inset-0 flex justify-center items-center" @click="show">
      <EllipsisHorizontalIcon class="h-4 w-4 mr-1" />
    </PopoverButton>

    <transition-appear origin="center">
      <PopoverPanel v-if="isOpened" class="absolute top-[5px] right-[5px] w-screen max-w-max" static>
        <OnClickOutside @trigger="close">
          <div class="isolate inline-flex rounded-md shadow-lg">
            <router-link :to="`/action-logs?search=${row.User.email}`" class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500">
              <AdjustmentsHorizontalIcon class="mr-2 h-3 text-slate-400" />
              Filter by user
            </router-link>

            <router-link :to="`/action-logs?search=${row.action}`" class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500">
              <AdjustmentsHorizontalIcon class="mr-2 h-3 text-slate-400" />
              Filter by action
            </router-link>
          </div>
        </OnClickOutside>
      </PopoverPanel>
    </transition-appear>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import TransitionAppear from './TransitionAppear.vue';
import { ref, watch } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import { EllipsisHorizontalIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: null
  },
  isRowHovered: {
    type: Boolean,
    default: false
  }
});

const isOpened = ref(false);

watch(() => props.isRowHovered, val => isOpened.value = val);

function show() {
  isOpened.value = true;
}

function close() {
  isOpened.value = false;
}
</script>
