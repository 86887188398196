<template>
  <Popover class="absolute inset-0">
    <PopoverButton class="absolute inset-0 flex justify-center items-center" @click="show">
      <EllipsisHorizontalIcon class="h-4 w-4 mr-1" />
    </PopoverButton>

    <transition-appear origin="center">
      <PopoverPanel v-if="isOpened" class="absolute top-[5px] right-[5px] w-screen max-w-max" static>
        <OnClickOutside @trigger="close">
          <div class="isolate inline-flex rounded-md shadow-lg">
            <router-link :to="`/sessions?search=${row.userId}`" class="h-8 relative -ml-px inline-flex rounded-l-md items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500">
              <UserIcon class="mr-2 h-3 text-slate-400" />
              Filter by this User
            </router-link>

            <router-link :to="`/session/${row.id}`" class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500">
              <EyeIcon class="mr-2 h-3 text-slate-400" />
              View
            </router-link>

            <button
              class="h-8 relative -ml-px inline-flex items-center rounded-r-md border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
              :class="isDisabled ? '!opacity-100 !text-slate-300' : ''"
              :disabled="isDisabled"
              @click="emit('terminate', row)"
            >
              <FireIcon
                class="mr-2 h-3"
                :class="isDisabled ? 'text-slate-200' : 'text-slate-400'"
              />
              Terminate
            </button>
          </div>
        </OnClickOutside>
      </PopoverPanel>
    </transition-appear>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import TransitionAppear from './TransitionAppear.vue';
import { computed, ref, watch } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import { EllipsisHorizontalIcon, EyeIcon, UserIcon, FireIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: null
  },
  isRowHovered: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([ 'terminate' ]);

const isOpened = ref(false);
const isDisabled = computed(() => props.row.state === 'saving' || props.row.state === 'terminating');

watch(() => props.isRowHovered, val => isOpened.value = val);

function show() {
  isOpened.value = true;
}

function close() {
  isOpened.value = false;
}
</script>
