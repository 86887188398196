<template>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
    <div class="bg-white p-4 rounded-lg shadow">
      <h3 class="text-gray-500 text-sm">Total Users</h3>
      <p class="text-2xl font-bold">{{ totalUsers }}</p>
    </div>
    <div class="bg-white p-4 rounded-lg shadow">
      <h3 class="text-gray-500 text-sm">Total Sessions</h3>
      <p class="text-2xl font-bold">{{ totalSessions }}</p>
    </div>
    <div class="bg-white p-4 rounded-lg shadow">
      <h3 class="text-gray-500 text-sm">Avg Session Time</h3>
      <p class="text-2xl font-bold">{{ formatTime(avgSessionTime) }}</p>
    </div>
  </div>

  <div class="bg-white rounded-lg shadow overflow-hidden">
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Program</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time Left</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Resolution</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Browser</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Os</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Device</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="session in sessions" :key="session.id" class="hover:bg-gray-50">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ session.email }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ session.program }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ dayjs(session.startedAt).utc().format('YYYY-MM-DD HH:mm') }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ formatTime(sessionDuration(session)) }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ formatTime(session.timeLeftSeconds) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ session.countryCode }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ session.resolution }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ session.browser }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ session.os }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ session.device }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
dayjs.extend(utc);

const props = defineProps({
  sessions: {
    type: Array,
    default: () => []
  },
  sessionDuration: {
    type: Function,
    required: true
  },
  formatTime: {
    type: Function,
    required: true
  }
});

const totalUsers = computed(() => new Set(props.sessions.map(session => session.email)).size);
const totalSessions = computed(() => props.sessions.length);
const avgSessionTime = computed(() => (props.sessions.length ? Math.ceil(props.sessions.reduce((acc, session) => acc + props.sessionDuration(session), 0) / totalSessions.value) : 0));
</script>
