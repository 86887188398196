<template>
  <dropdown-default v-if="user" v-model="model" :items="items" :placement="isMobile ? 'bottom-end' : 'top-start'" :auto-placement="false">
    <div class="w-full flex items-center gap-x-4 text-sm font-semibold leading-6 text-gray-900 lg:px-6 lg:py-3 lg:hover:bg-gray-50">
      <img v-if="user.avatar" :src="user.avatar" class="h-8 w-8 rounded-full bg-gray-50" alt="">
      <svg v-else class="h-8 w-8 rounded-full bg-gray-50" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2091 4.66667C11.2091 6.13943 10.0151 7.33333 8.5424 7.33333C7.06964 7.33333 5.87573 6.13943 5.87573 4.66667C5.87573 3.19391 7.06964 2 8.5424 2C10.0151 2 11.2091 3.19391 11.2091 4.66667Z" stroke="currentColor" />
        <path d="M9.87573 9.33331H7.20907C5.36812 9.33331 3.87573 10.8257 3.87573 12.6666C3.87573 13.403 4.47269 14 5.20907 14H11.8757C12.6121 14 13.2091 13.403 13.2091 12.6666C13.2091 10.8257 11.7167 9.33331 9.87573 9.33331Z" stroke="currentColor" stroke-linejoin="round" />
      </svg>
      <span class="sr-only">Your profile</span>
      <span class="hidden lg:block" aria-hidden="true">{{ user.email }}</span>
    </div>
  </dropdown-default>
</template>

<script setup>
import { watch } from 'vue';
import DropdownDefault from './DropdownDefault.vue';
import { isMobile } from '../composables/useBreakpoints';

defineProps({
  user: {
    type: Object,
    default: () => ({})
  },
  items: {
    type: Array,
    default: () => []
  }
});

const model = defineModel({ type: Object, default: null });

const emit = defineEmits([ 'clicked' ]);

watch(model, value => {
  if (value) {
    emit('clicked', value);
    model.value = null;
  }
});
</script>
