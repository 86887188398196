import { createRouter, createWebHistory } from 'vue-router';
import { signOut, doesSessionExist, getUserAsync, isAdmin } from './composables/useAuth.js';

import DashboardPage from './pages/DashboardPage.vue';
import LoggedUserPage from './pages/LoggedUserPage.vue';
import LoginPage from './pages/LoginPage.vue';
import ProgramsPage from './pages/ProgramsPage.vue';
import ProgramPage from './pages/ProgramPage.vue';
import ProgramSeoPage from './pages/ProgramSeoPage.vue';
import UsersPage from './pages/UsersPage.vue';
import UserPage from './pages/UserPage.vue';
import NotFoundPage from './pages/NotFoundPage.vue';
import StripeWebhooksPage from './pages/StripeWebhooksPage.vue';
import StripeWebhookPage from './pages/StripeWebhookPage.vue';
import SessionsPage from './pages/SessionsPage.vue';
import SessionPage from './pages/SessionPage.vue';
import ActionLogs from './pages/ActionLogs.vue';
import ActionLog from './pages/ActionLog.vue';

let authorizeResult = null;

async function isAuthorized() {
  if (authorizeResult === null) {
    const _isAuthorized = await doesSessionExist();
    authorizeResult = { isAuthorized: _isAuthorized };
  }

  return authorizeResult.isAuthorized;
}

async function adminOnly(_to, _from, next) {
  await getUserAsync();
  if (isAdmin()) {
    next();
  } else {
    next('/');
  }
}

const routes = [
  {
    path: '/',
    component: LoggedUserPage,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      {
        path: 'dashboard',
        component: DashboardPage
      },
      {
        path: 'programs',
        component: ProgramsPage
      },
      {
        path: 'program/:programId',
        component: ProgramPage,
        props: true,
        beforeEnter: adminOnly
      },
      {
        path: 'program/seo/:programId',
        component: ProgramSeoPage,
        props: true
      },
      {
        path: 'program',
        component: ProgramPage,
        props: { programId: null }
      },
      {
        path: 'users',
        component: UsersPage,
        beforeEnter: adminOnly
      },
      {
        path: 'user/:userId',
        component: UserPage,
        props: true,
        beforeEnter: adminOnly
      },
      {
        path: 'user',
        component: UserPage,
        props: { userId: null },
        beforeEnter: adminOnly
      },
      {
        path: 'stripe-webhooks',
        component: StripeWebhooksPage,
        beforeEnter: adminOnly
      },
      {
        path: 'stripe-webhook/:id',
        component: StripeWebhookPage,
        props: true,
        beforeEnter: adminOnly
      },
      {
        path: 'sessions',
        component: SessionsPage,
        beforeEnter: adminOnly
      },
      {
        path: 'session/:id',
        component: SessionPage,
        props: true,
        beforeEnter: adminOnly
      },
      {
        path: 'action-logs',
        component: ActionLogs,
        beforeEnter: adminOnly
      },
      {
        path: 'action-log/:id',
        component: ActionLog,
        props: true,
        beforeEnter: adminOnly
      }
    ]
  },

  {
    path: '/login',
    component: LoginPage,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/dashboard');
      } else {
        next();
      }
    }
  },
  {
    path: '/logout',
    component: {
      render: () => {}
    },
    beforeEnter: async () => {
      await signOut();
      router.push('/login');
    }
  },

  {
    path: '/404',
    name: 'NotFound',
    component: NotFoundPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.querySelector('#app');
    if (element) {
      element.scrollTo(0, 0);
      return null;
    }

    return { top: 0 };
  }
});

router.afterEach(() => {
  // reset the authorization check
  authorizeResult = null;
});

export default router;
