<template>
  <page-header title="Stripe Webhook" :identifier="id" />

  <JSONView v-if="isLoaded" :data="webHook" root-key="Webhook" no-border />

  <div v-else>
    Loading...
  </div>
</template>

<script setup>
import { ref } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import { JSONView } from 'vue-json-component-vue-3';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';

const props = defineProps({
  id: {
    type: String,
    required: true
  }
});

const isLoaded = ref(false);
const webHook = ref({});

if (props.id) {
  load();
} else {
  isLoaded.value = true;
}

async function load() {
  const { data } = await useFetch(`${apiDomain}/api/stripe/${props.id}`).get().json();
  webHook.value = data.value;
  isLoaded.value = true;
}
</script>
