<template>
  <page-header title="Session" :identifier="id" />

  <div v-if="isLoaded" class="grid grid-cols-1 2xl:grid-cols-3 gap-10">
    <!-- Session Info Card -->
    <div class="col-span-1 bg-white rounded-lg">
      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-2 mb-4">Session Information</h2>
      <div class="grid grid-cols-1 gap-2 text-sm">
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Session ID:</span>
          <span class="text-gray-800 break-all">{{ session.id }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Device info:</span>
          <span class="text-gray-800 break-all">{{ parseDeviceInfo(session.deviceInfo) }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">State:</span>
          <span :class="['font-bold', (session.state === 'terminating' || session.state === 'saving') ? 'text-primary-600' : 'text-red-600']">{{ session.state }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Gateway Address:</span>
          <span class="text-gray-800">{{ session.gatewayAddress }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Client IP:</span>
          <span class="text-gray-800">{{ session.clientIp }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Started At:</span>
          <span class="text-gray-800">{{ formatDate(session.startedAt) }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Finished At:</span>
          <span class="text-gray-800">{{ formatDate(session.finishedAt) }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Created At:</span>
          <span class="text-gray-800">{{ formatDate(session.createdAt) }}</span>
        </div>
      </div>

      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-2 mb-4 mt-6">User Information</h2>
      <div class="grid grid-cols-1 gap-2 text-sm">
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">User ID:</span>
          <span class="text-gray-800 break-all">{{ session.userId }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Name:</span>
          <span class="text-gray-800">{{ session.User.name }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Email:</span>
          <span class="text-gray-800">{{ session.User.email }}</span>
        </div>
      </div>

      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-2 mb-4 mt-6">Program Information</h2>
      <div class="grid grid-cols-1 gap-2 text-sm">
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Program ID:</span>
          <span class="text-gray-800">{{ session.programId }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Name:</span>
          <span class="text-gray-800">{{ session.Program.name }}</span>
        </div>
      </div>
    </div>

    <!-- Logs and Stream Card -->
    <div class="col-span-2 bg-white rounded-lg">
      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-2 mb-4">Stream Information</h2>
      <div v-if="session.Stream" class="grid grid-cols-1 gap-2 text-sm">
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Link:</span>
          <span class="text-gray-800">{{ session.Stream.link }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Status:</span>
          <span :class="['font-bold', session.Stream.status === 'end' ? 'text-primary-600' : 'text-gray-800']">{{ session.Stream.status }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Gateway Address:</span>
          <span class="text-gray-800">{{ session.Stream.gatewayAddress }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Seats:</span>
          <span class="text-gray-800">{{ session.Stream.seats }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">User:</span>
          <span class="text-gray-800">{{ session.Stream.userInfo }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Program:</span>
          <span class="text-gray-800">{{ session.Stream.programInfo }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Created At:</span>
          <span class="text-gray-800">{{ formatDate(session.Stream.createdAt) }}</span>
        </div>
      </div>

      <h2 class="text-xl font-semibold text-gray-700 pb-2 mt-6">Session Logs</h2>
      <div class="overflow-x-auto max-h-96 overflow-y-auto">
        <table class="w-full text-sm text-left text-gray-700">
          <thead class="bg-primary-600 text-white sticky top-0">
            <tr>
              <th class="p-3">ID</th>
              <th class="p-3">Method</th>
              <th class="p-3">Destination</th>
              <th class="p-3">Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in session.Logs" :key="log.id" class="hover:bg-gray-50">
              <td class="p-3">{{ log.id }}</td>
              <td class="p-3">{{ log.data.method }}</td>
              <td class="p-3">{{ log.data.destinationFolder || '-' }}</td>
              <td class="p-3">{{ formatDate(log.createdAt) }}</td>
            </tr>
            <tr>
              <td colspan="4" class="p-3 text-gray-600">Total Logs: {{ session.Logs.length }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div v-else>Loading...</div>
</template>

<script setup>
import { ref } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';
import { UAParser } from 'ua-parser-js';

const props = defineProps({
  id: {
    type: String,
    required: true
  }
});

const isLoaded = ref(false);
const session = ref({});

if (props.id) {
  load();
} else {
  isLoaded.value = true;
}

async function load() {
  const { data } = await useFetch(`${apiDomain}/api/session/${props.id}`).get().json();
  session.value = data.value;
  isLoaded.value = true;
}

function formatDate(dateString) {
  if (!dateString) {
    return '-';
  }

  return new Date(dateString).toLocaleString('en-GB', { timeZone: 'UTC', dateStyle: 'medium', timeStyle: 'long' });
}

function parseDeviceInfo(deviceInfo) {
  if (!deviceInfo) {
    return '-';
  }

  let screen = '';
  if (deviceInfo.screen) {
    screen = `, ${deviceInfo.screen.width}x${deviceInfo.screen.height}`;
  }

  const parsed = deviceInfo.ua ? UAParser(deviceInfo.ua) : parseWhichBrowser(deviceInfo);

  return `${parsed.browser.name} ${parsed.browser.version}, ${parsed.os.name} ${parsed.os.version}, ${parsed.device.vendor} ${parsed.device.model}${screen}`;
}

function parseWhichBrowser(data) {
  const os = data.os || {};
  const osVersion = os.version || {};
  const device = data.device || {};
  const browser = data.browser || {};
  const browserVersion = browser.version || {};

  return {
    os: {
      name: os.alias || os.name || 'Unknown OS',
      version: osVersion.value || ''
    },
    device: {
      model: device.model || 'Unknown Device',
      vendor: device.manufacturer || 'Unknown Device'
    },
    browser: {
      name: browser.name || 'Unknown Browser',
      version: browserVersion.value || ''
    }
  };
}
</script>
