<template>
  <button ref="button" class="flex btn-outline size-10! p-2!" @click="rotate">
    <ArrowPathIcon class="size-4" :class="isRotated ? 'animate-spin' : null" />
  </button>
</template>

<script setup>
import { ref } from 'vue';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';

const isRotated = ref(false);
const button = ref(null);

function rotate() {
  isRotated.value = !isRotated.value;
  button.value?.blur();
  setTimeout(() => {
    isRotated.value = false;
  }, 200);
}
</script>
