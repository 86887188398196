<template>
  <div class="flex items-center justify-center h-screen">
    <div class="w-96">
      <h1 class="text-3xl font-bold text-center">Login</h1>
      <form class="mt-6" @submit.prevent="submit">
        <input v-model="email" type="text" class="w-full p-2 border border-gray-300 rounded-sm mt-2 focus:outline-primary-500" placeholder="Email" autocomplete="email">
        <input v-model="password" type="password" class="w-full p-2 border border-gray-300 rounded-sm mt-2 focus:outline-primary-500" placeholder="Password" autocomplete="current-password">
        <button class="w-full bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded-sm mt-2">Login</button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { signIn } from '../composables/useAuth';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const email = ref('');
const password = ref('');
const router = useRouter();
const toast = useToast();

async function submit() {
  const { success, error } = await signIn(email.value, password.value);

  if (success) {
    router.push('/dashboard');
    return;
  }

  if (error) {
    toast.error(error);
  }
}
</script>
