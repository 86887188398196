<template>
  <page-header title="Log" :identifier="id" />

  <div v-if="isLoaded" class="grid grid-cols-1 2xl:grid-cols-3 gap-2">
    <!-- User info -->
    <div class="bg-white shadow rounded-lg p-4 mb-6">
      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-1 mb-2">User Information</h2>
      <div class="grid grid-cols-1 gap-2 text-sm">
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">User ID:</span>
          <span class="text-gray-800 break-all">{{ log.userId }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Name:</span>
          <span class="text-gray-800">{{ log.User.name }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Email:</span>
          <span class="text-gray-800">{{ log.User.email }}</span>
        </div>
      </div>
    </div>

    <!-- Action info -->
    <div class="bg-white shadow rounded-lg p-4 mb-6">
      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-1 mb-2">Action Information</h2>
      <div class="grid grid-cols-1 gap-2 text-sm">
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Action:</span>
          <span class="text-gray-800 break-all">{{ log.action }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Status code:</span>
          <span class="text-gray-800 break-all">{{ log.statusCode }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Status message:</span>
          <span class="text-gray-800 break-all">{{ log.statusMessage }}</span>
        </div>
        <div class="flex">
          <span class="font-bold text-gray-600 w-32">Created At:</span>
          <span class="text-gray-800">{{ formatDate(log.createdAt) }}</span>
        </div>
      </div>
    </div>

    <!-- JSON Details -->
    <div v-if="log?.details" class="bg-white p-4 rounded-lg shadow">
      <h2 class="text-xl font-semibold text-gray-700 border-b-2 border-primary-500 pb-1 mb-2">Log Details</h2>
      <div class="bg-gray-900 p-4 rounded-lg">
        <JSONView :data="log.details" root-key="Deatils" />
      </div>
    </div>
  </div>

  <div v-else>Loading...</div>
</template>

<script setup>
import { ref } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import { JSONView } from 'vue-json-component-vue-3';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';

const props = defineProps({
  id: {
    type: String,
    required: true
  }
});

const isLoaded = ref(false);
const log = ref({});

if (props.id) {
  load();
} else {
  isLoaded.value = true;
}

async function load() {
  const { data } = await useFetch(`${apiDomain}/api/action-log/${props.id}`).get().json();
  log.value = data.value;
  isLoaded.value = true;
}

function formatDate(dateString) {
  if (!dateString) {
    return '-';
  }

  return new Date(dateString).toLocaleString('en-GB', { timeZone: 'UTC', dateStyle: 'medium', timeStyle: 'long' });
}

</script>
