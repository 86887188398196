<template>
  <div class="relative">
    <label class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>

    <draggable v-model="model" item-key="large" animation="100" class="snap-mandatory snap-x flex gap-x-5 mt-2">
      <template #header>
        <file-uploader multiple @uploaded="onUploaded">
          <div class="size-28 rounded-md bg-zinc-100 flex justify-center items-center" :class="{'border-[1px] border-red-500': errorMsg}">
            <PhotoIcon class="size-10 text-zinc-400" aria-hidden="true" />
          </div>
        </file-uploader>
      </template>

      <template #item="{element, index}">
        <div
          class="size-28 rounded-md snap-left overflow-hidden cursor-move relative"
        >
          <img :src="element" class="w-full h-full object-cover">

          <div
            class="absolute top-0.5 left-0.5 size-6 rounded-sm bg-white/70 flex justify-center items-center group hover:bg-white/85"
            @click="makeMainImage(element)"
          >
            <CheckCircleIcon
              v-tooltip="{ content: 'Set as main', distance: 10 }"
              class="size-5 text-primary-600 group-hover:text-primary-700 focus:outline-hidden cursor-pointer"
            />
          </div>

          <div
            class="absolute top-0.5 right-0.5 size-6 rounded-sm bg-white/70 flex justify-center items-center group hover:bg-white/85"
            @click="removeImage(index)"
          >
            <TrashIcon
              v-tooltip="{ content: 'Delete image', distance: 10 }"
              class="size-5 text-red-600 group-hover:text-red-700 focus:outline-hidden cursor-pointer"
            />
          </div>
        </div>
      </template>
    </draggable>

    <div v-show="errorMsg" class="absolute text-xs text-red-500 mt-0.5">{{ errorMsg }}</div>
  </div>
</template>

<script setup>
import { PhotoIcon, TrashIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';
import draggable from 'vuedraggable';

import FileUploader from './FileUploader.vue';

defineProps({
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  errorMsg: {
    type: String,
    default: ''
  }
});

const model = defineModel({
  type: Array,
  default: () => []
});

const emit = defineEmits([ 'mainImage' ]);

function onUploaded(urls) {
  model.value = [ ...urls, ...model.value ];
}

function makeMainImage(img) {
  emit('mainImage', img);
}

function removeImage(index) {
  model.value.splice(index, 1);
}
</script>
