<template>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
    <div class="bg-white p-4 rounded-lg shadow">
      <h3 class="text-gray-500 text-sm">Total Users</h3>
      <p class="text-2xl font-bold">{{ totalUsers }}</p>
    </div>
    <div class="bg-white p-4 rounded-lg shadow">
      <h3 class="text-gray-500 text-sm">Total Sessions</h3>
      <p class="text-2xl font-bold">{{ totalSessions }}</p>
    </div>
    <div class="bg-white p-4 rounded-lg shadow">
      <h3 class="text-gray-500 text-sm">Avg Session Time</h3>
      <p class="text-2xl font-bold">{{ formatTime(avgSessionTime) }}</p>
    </div>
  </div>

  <div class="bg-white rounded-lg shadow overflow-hidden">
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Registered At</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Is Email Confirmed</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Session Count</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time Left</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Language</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Login Method</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UTM Campaign</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UTM Content</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UTM Medium</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UTM Source</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UTM Term</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="user in users" :key="user.id" class="hover:bg-gray-50">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ user.email }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ dayjs(user.createdAt).utc().format('YYYY-MM-DD HH:mm') }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.isEmailConfirmed }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.sessionCount }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ formatTime(user.timeLeftSeconds) }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.countryCode }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.language }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.loginMethod }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.utm_campaign }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.utm_content }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.utm_medium }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.utm_source }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ user.utm_term }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
dayjs.extend(utc);

const props = defineProps({
  users: {
    type: Array,
    default: () => []
  },
  formatTime: {
    type: Function,
    required: true
  }
});

const totalUsers = computed(() => props.users.length);
const totalSessions = computed(() => props.users.reduce((acc, user) => acc + parseInt(user.sessionCount, 10), 0));
const avgSessionTime = computed(() => {
  const total = props.users.reduce((acc, user) => acc + parseInt(user.sessionCount, 10), 0);
  if (!total) {
    return 0;
  }
  const totalDuration = props.users.reduce((acc, user) => acc + user.timeLeftSeconds, 0);
  return totalDuration / total;
});
</script>
