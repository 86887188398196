<template>
  <div>
    <label class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>

    <file-uploader @uploaded="onUploaded">
      <div v-if="model" class="mt-2 flex items-center gap-x-3">
        <img :src="model" class="size-12 rounded-md object-cover">
        <button type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Change</button>
      </div>

      <div v-else class="mt-2 flex items-center gap-x-3">
        <div class="size-12 rounded-md bg-zinc-100 flex justify-center items-center" :class="{'border-[1px] border-red-500': errorMsg}">
          <PhotoIcon class="size-4 text-zinc-400" aria-hidden="true" />
        </div>
        <button type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Add</button>
      </div>
      <div v-show="errorMsg" class="absolute text-xs text-red-500 mt-0.5">{{ errorMsg }}</div>
    </file-uploader>
  </div>
</template>

<script setup>
import { PhotoIcon } from '@heroicons/vue/24/outline';
import FileUploader from './FileUploader.vue';

defineProps({
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  errorMsg: {
    type: String,
    default: ''
  }
});

const model = defineModel({
  type: String,
  default: null
});

function onUploaded(urls) {
  model.value = urls[0];
}
</script>
