<template>
  <div>
    <label :for="uid" class="flex items-center gap-x-2 text-sm font-medium leading-6 text-gray-900">
      {{ label }}
      <slot name="label-color" />
    </label>
    <div class="mt-1 relative">
      <input
        :id="uid"
        v-model="model"
        :placeholder="placeholder"
        :type="type"
        :min="range[0]"
        :max="range[1]"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
        :class="{'ring-red-500': errorMsg}"
        :readonly="readonly"
      >
      <div v-show="errorMsg" class="absolute text-xs text-red-500 mt-0.5">{{ errorMsg }}</div>
    </div>
  </div>
</template>

<script setup>
import { useId } from 'vue';

defineProps({
  type: {
    type: String,
    default: 'text'
  },
  range: {
    type: Array,
    default: () => [ -9999, 9999 ]
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  errorMsg: {
    type: String,
    default: ''
  },
  readonly: {
    type: Boolean,
    default: false
  }
});

const uid = useId();

const model = defineModel({
  type: [ String, Number ],
  default: null
});
</script>
