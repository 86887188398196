<template>
  <form class="max-w-5xl" autocomplete="off" @submit.prevent="emit('save')">
    <div class="mt-10 mb-40 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
      <form-input v-model="seoMeta.title" label="Title" class="md:col-span-4" :error-msg="result.title.$messages.join(' | ')" />

      <form-textarea v-model="seoMeta.description" label="Description" class="col-span-4" />

      <hr class="col-span-4 mt-4">

      <form-input v-model="seoMeta.ogTitle" label="og:title" class="md:col-span-4" />

      <form-textarea v-model="seoMeta.ogDescription" label="og:description" class="col-span-4" />

      <form-input v-model="seoMeta.ogImage" label="og:image URL" class="md:col-span-4" />

      <hr class="col-span-4 mt-4">

      <form-input v-model="seoMeta.twitterTitle" label="twitter:title" class="md:col-span-4" />

      <form-textarea v-model="seoMeta.twitterDescription" label="twitter:description" class="col-span-4" />

      <form-input v-model="seoMeta.twitterImage" label="twitter:image URL" class="md:col-span-4" />
    </div>
  </form>
</template>

<script setup>
import { reactive, watch } from 'vue';
import FormInput from './FormInput.vue';
import FormTextarea from './FormTextarea.vue';
// import FormRadioGroup from './FormRadioGroup.vue';
// import FormSelect from './FormSelect.vue';
// import FormImageUpload from './FormImageUpload.vue';
// import FormMultipleImagesUpload from './FormMultipleImagesUpload.vue';
// import ModalPrompt from './ModalPrompt.vue';
// import { PlusIcon } from '@heroicons/vue/24/outline';
// import { getStats, reloadStats } from '../composables/useProgramStats';
// import { Modal } from '../composables/useModal';
import useValidate from 'vue-tiny-validate';

const seoMeta = defineModel('seoMeta', {
  type: Object,
  default: () => ({
    name: ''
  })
});

const emit = defineEmits([ 'save' ]);

defineExpose({
  onValidate,
  getResult
});

const rules = reactive({
  title: {
    name: 'required',
    test: () => true,
    message: 'Title must not be empty.'
  },
  description: {
    name: 'required',
    test: () => true,
    message: 'Title must not be empty.'
  },
  ogTitle: {
    name: 'required',
    test: () => true,
    message: 'Title must not be empty.'
  },
  ogDescription: {
    name: 'required',
    test: () => true,
    message: 'Title must not be empty.'
  },
  ogImage: {
    name: 'required',
    test: () => true,
    message: 'Image must not be valid URL.'
  },
  twitterTitle: {
    name: 'required',
    test: () => true,
    message: 'Title must not be empty.'
  },
  twitterDescription: {
    name: 'required',
    test: () => true,
    message: 'Title must not be empty.'
  },
  twitterImage: {
    name: 'required',
    test: () => true,
    message: 'Image must not be valid URL.'
  }
});

watch(() => seoMeta.value.ogTitle, val => seoMeta.value.twitterTitle = val);
watch(() => seoMeta.value.ogDescription, val => seoMeta.value.twitterDescription = val);

const options = reactive({
  autoTest: true
});
const { result } = useValidate(seoMeta, rules, options);

async function onValidate() {
  return await result.value.$test();
}

function getResult() {
  return result.value;
}
</script>
