<template>
  <Popover class="absolute inset-0">
    <PopoverButton class="absolute inset-0 flex justify-center items-center" @click="show">
      <EllipsisHorizontalIcon class="h-4 w-4 mr-1" />
    </PopoverButton>

    <transition-appear origin="center">
      <PopoverPanel v-if="isOpened" class="absolute top-[5px] right-[5px] w-screen max-w-max" static>
        <OnClickOutside @trigger="close">
          <div class="isolate inline-flex rounded-md shadow-lg">
            <template v-if="isAdmin()">
              <button
                type="button"
                class="h-8 relative inline-flex items-center rounded-l-md border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
                @click="emit('delete', row)"
              >
                <TrashIcon class="mr-2 h-3 text-slate-400" />
                Delete
              </button>

              <button
                v-if="row.deletedAt"
                type="button"
                class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
                @click="emit('showProgram', row)"
              >
                <EyeIcon class="mr-2 h-3 text-slate-400" />
                Show
              </button>

              <button
                v-else
                type="button"
                class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
                @click="emit('hideProgram', row)"
              >
                <EyeSlashIcon class="mr-2 h-3 text-slate-400" />
                Hide
              </button>

              <button
                v-if="row.isEnabled"
                type="button"
                class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
                @click="emit('disable', row)"
              >
                <LockClosedIcon class="mr-2 h-3 text-slate-400" />
                Disable
              </button>

              <button
                v-else
                type="button"
                class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
                @click="emit('enable', row)"
              >
                <LockOpenIcon class="mr-2 h-3 text-slate-400" />
                Enable
              </button>
            </template>

            <router-link
              :to="`/program/seo/${row.id}`"
              class="h-8 relative -ml-px inline-flex items-center border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
              :class="{ 'rounded-md': !isAdmin() }"
            >
              <SparklesIcon class="mr-2 h-3 text-slate-400" />
              SEO
            </router-link>

            <router-link
              v-if="isAdmin()"
              :to="`/program/${row.id}`"
              class="h-8 relative -ml-px inline-flex items-center rounded-r-md border border-slate-300 bg-white px-4 py-2 text-xs font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-black-500 focus:outline-hidden focus:ring-1 focus:ring-black-500"
              :class="{ 'rounded-l-md': !isAdmin() }"
            >
              <PencilSquareIcon class="mr-2 h-3 text-slate-400" />
              Edit
            </router-link>
          </div>
        </OnClickOutside>
      </PopoverPanel>
    </transition-appear>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import TransitionAppear from './TransitionAppear.vue';
import { ref, watch } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import { EllipsisHorizontalIcon, TrashIcon, PencilSquareIcon, EyeIcon, EyeSlashIcon, LockOpenIcon, LockClosedIcon, SparklesIcon } from '@heroicons/vue/24/outline';
import { isAdmin } from '../composables/useAuth';

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: null
  },
  isRowHovered: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([ 'delete', 'showProgram', 'hideProgram', 'enable', 'disable' ]);

const isOpened = ref(false);

watch(() => props.isRowHovered, val => isOpened.value = val);

function show() {
  isOpened.value = true;
}

function close() {
  isOpened.value = false;
}
</script>
