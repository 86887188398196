<template>
  <page-header title="Logs" />

  <programs-empty v-if="isEmpty && search === null" />

  <template v-else>
    <div class="flex justify-between mb-4">
      <input-search v-model.trim="search" class="max-w-lg grow" />

      <reload-button class="ml-4" @click="reloadGrid" />
    </div>

    <grid
      ref="gridRef"
      :data-handler="getActionLogs"
      :columns="columns"
      :row-class="rowClass"
      :per-page="15"
    />
  </template>
</template>

<script setup>
import { ref, markRaw, computed } from 'vue';
import PageHeader from '../components/PageHeader.vue';
import ReloadButton from '../components/ReloadButton.vue';
import InputSearch from '../components/InputSearch.vue';
import ProgramsEmpty from '../components/ProgramsEmpty.vue';
import Grid from '../components/grid/Grid.vue';
import SessionUserIcon from '../components/SessionUserIcon.vue';
import LogListActions from '../components/LogListActions.vue';
import { useFetch, watchDebounced, useUrlSearchParams } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { apiDomain } from '../composables/useConstants';

const gridRef = ref(null);
const isEmpty = ref(false);
const router = useRouter();

function rowClass(_row, index) {
  if (_row.User.tier === 'admin') {
    return 'bg-primary-100/50';
  }
  if (_row.User.tier === 'seo') {
    return 'bg-fuchsia-100/50';
  }
  if (_row.User.tier === 'staff') {
    return 'bg-sky-100/50';
  }
  return index % 2 ? 'bg-zinc-200/10' : '';
}

function reloadGrid(updateQuery = {}) {
  if (gridRef.value?.reload) {
    gridRef.value.reload(updateQuery);
  }
}

const params = useUrlSearchParams('history', { initialValue: { search: null } });

const search = computed({
  get: () => params.search,
  set: value => params.search = value
});

watchDebounced(
  search,
  () => {
    reloadGrid({ offset: 0 });
  },
  { debounce: 500, maxWait: 1000 }
);

function getUrl({ limit, offset, sort, direction }) {
  const data = { limit, offset, order: sort, direction: direction?.toLowerCase(), search: search.value ?? '' };

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/action-log/?${searchParams}`;
}

async function getActionLogs(args) {
  const { limit, offset, sort, direction } = args;
  const { data } = await useFetch(getUrl({ limit, offset, sort, direction })).get().json();

  isEmpty.value = false;

  return {
    total: data.value.total,
    rows: data.value.logs
  };
}

const columns = [
  {
    title: '#',
    prop: 'id',
    class: 'cursor-pointer text-xs font-mono',
    sortable: true,
    click: row => router.push(`/action-log/${row.id}`)
  },
  {
    title: 'User',
    prop: 'userId',
    class: 'cursor-pointer !py-0',
    component: markRaw(SessionUserIcon),
    click: row => router.push(`/user/${row.userId}`),
    sortable: true
  },
  {
    title: 'Action',
    prop: 'action',
    class: 'cursor-pointer !py-0',
    click: row => router.push(`/action-log/${row.id}`)
  },
  {
    title: 'Status code',
    prop: 'statusCode',
    class: 'cursor-pointer !py-0',
    click: row => router.push(`/action-log/${row.id}`)
  },
  {
    title: 'Status message',
    prop: 'statusMessage',
    class: 'cursor-pointer !py-0',
    click: row => router.push(`/action-log/${row.id}`)
  },
  {
    title: 'Created',
    prop: 'createdAt',
    value: row => new Date(row.createdAt).toLocaleString('en-GB', { timeZone: 'UTC', dateStyle: 'medium', timeStyle: 'long' }),
    sortable: true
  },
  {
    class: 'no-animation text-right max-w-max! z-10 relative',
    component: markRaw(LogListActions)
  }
];
</script>
